@import '~bloko/common/styles/media/_screen';
@import '@hh.ru/magritte-design-tokens/base-variables';

.principle-list {
    .magritte-typography-paragraph-2-regular();

    padding-left: 24px;
}

.principle-list-item {
    list-style-type: decimal;
}

.how-auction-works-image {
    background-image: url('how-it-works.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 260px;

    @media @screen-gt-xs {
        height: 296px;
    }
}

.factor-list {
    .magritte-typography-paragraph-2-regular();

    padding-left: 24px;
}

.factor-list-item {
    list-style-type: disc;
}
