.bid-rating-wrapper {
    position: relative;
}

.bid-rating-wrapper-content {
    display: flex;
    flex-direction: row;
    gap: 0 16px;
}

.bid-rating-wrapper-loader {
    display: none;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}

.bid-rating-wrapper_loading,
.bid-rating-wrapper_loading-with-children {
    .bid-rating-wrapper-content {
        visibility: hidden;
    }

    .bid-rating-wrapper-loader {
        display: block;
    }
}

.bid-rating-wrapper_loading-with-children {
    .bid-rating-wrapper-loader {
        left: 50%;
        top: 25%;
    }
}
