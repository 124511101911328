@import '../../../bloko/common/styles/media/_screen';

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 12px;
}

.row_disable-on-xs {
    display: initial;

    @media @screen-gt-xs {
        display: flex;
    }
}

.row_align-items-start {
    align-items: start;
}
