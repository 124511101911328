@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/text-base';

.wrapper {
    display: flex;
    flex-flow: column;

    @media @screen-gt-xs {
        flex-flow: row;
        justify-content: space-between;
    }
}

.input-block {
    width: 100%;
}

.input-separator {
    @media @screen-gt-xs {
        flex-grow: 0;
        flex-shrink: 0;
        width: 28px;
    }
}
